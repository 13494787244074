import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn
} from 'actions/Auth';
import { SHButton } from 'components/Button';

const SignIn = (props) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [email, setEmail] = useState('rahul.vagadiya+administrator@gmail.com');
  // const [password, setPassword] = useState('123.com');
  const dispatch = useDispatch();
  const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      props.history.push('/');
    }
  }, [showMessage, authUser, props.history, dispatch]);

  const handleSubmit = (event) => {
    dispatch(showAuthLoader());
    dispatch(userSignIn({email, password}));
    event.preventDefault();
  }

  return (
    <div className="app-login-container hi d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">

        <div className="app-logo-content d-flex align-items-center justify-content-center">

        </div>

        <div className="app-login-content">
          <div class="my-4 small text-center d-block terms">By logging in, you accept our <a href="https://stayhopper.com/extranetTerms.html">Terms of Use</a>, and <a href="https://stayhopper.com/privacy.html">Privacy &amp; Cookies Statement</a>.</div>
          <div class="login-form-wrapper">
          <Link className="logo-lg" to="/" title="Stayhopper">
            <img width="177" src={require("assets/images/logo-color.png")} alt="StayHopper" title="StayHopper"/>
          </Link>
            <div className="app-login-header mb-4">
              <h1>Sign in to Extranet and manage your property</h1>
              {props.location.state && props.location.state.message && <p className="text-primary">{props.location.state.message}</p>}
            </div>
            <div className="app-login-form">
            <form onSubmit={handleSubmit}>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.email"/>}
                  fullWidth
                  onChange={(event) => setEmail(event.target.value)}
                  defaultValue={email}
                  margin="normal"
                  className="mt-1 mb-3 my-sm-3"
                />
                <TextField
                  type="password"
                  label={<IntlMessages id="appModule.password"/>}
                  fullWidth
                  onChange={(event) => setPassword(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  className="mt-1 mb-3 my-sm-3"
                />

                <div className="mb-3 mt-sm-3 d-flex align-items-center justify-content-between button-wrapper">
                  <SHButton type="submit">
                    <IntlMessages id="appModule.signIn"/>
                  </SHButton>

                  <Link to="/forgot-password">Forgot password?</Link>
                </div>
                

                <div className="app-social-block my-1 my-sm-3" hidden>
                  <IntlMessages id="signIn.connectWith"/>
                  <ul className="social-link">
                    <li>
                      <IconButton className="icon"
                                  onClick={() => {
                                    dispatch(showAuthLoader());
                                    dispatch(userFacebookSignIn());
                                  }}>
                        <i className="zmdi zmdi-facebook"/>
                      </IconButton>
                    </li>

                    <li>
                      <IconButton className="icon"
                                  onClick={() => {
                                    dispatch(showAuthLoader());
                                    dispatch(userTwitterSignIn());
                                  }}>
                        <i className="zmdi zmdi-twitter"/>
                      </IconButton>
                    </li>

                    <li>
                      <IconButton className="icon"
                                  onClick={() => {
                                    dispatch(showAuthLoader());
                                    dispatch(userGoogleSignIn());

                                  }}>
                        <i className="zmdi zmdi-google-plus"/>
                      </IconButton>
                    </li>

                    <li>
                      <IconButton className="icon"
                                  onClick={() => {
                                    dispatch(showAuthLoader());
                                    dispatch(userGithubSignIn());
                                  }}>
                        <i className="zmdi zmdi-github"/>
                      </IconButton>
                    </li>
                  </ul>
                </div>

              </fieldset>
            </form>
          </div>
          </div>



        </div>

      </div>
      {
        loader &&
        <div className="loader-view">
          <CircularProgress/>
        </div>
      }
      {showMessage && NotificationManager.error(alertMessage)}

      <NotificationContainer/>
    </div>
  );
};


export default SignIn;
