import axios from 'axios';
import config from '../../config'

const api = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  }
});

export const setBearerToken = (access_token) => {
  if (access_token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
  } else {

    // Remove token (possibly logout)
    api.defaults.headers.common['Authorization'] = ``;
  }
}

export default api