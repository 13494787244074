import api from '.';

const PropertiesApi = {
  async list(params) {
    params = params || {};
    params.page = params.page || 1;
    params.limit = (typeof params.limit !== 'undefined') ? params.limit : 10;
    params.order = params.order || 'asc';
    params.orderBy = params.orderBy || 'name';
    params.q = params.q || '';
    params.company = params.company || '';
    params.approved = (typeof params.approved) === 'undefined' ? '' : params.approved;
    params.published = (typeof params.published) === 'undefined' ? '' : params.published;
    params.source = params.source || '';
    params.country = params.country || '';
    params.city = params.city || '';
    params.isAgreementSigned = (typeof params.isAgreementSigned) === undefined ? '' : params.isAgreementSigned;
    return api.get(`properties?page=${params.page}&limit=${params.limit}&order=${params.order}&orderBy=${params.orderBy}&q=${params.q}&company=${params.company}&approved=${params.approved}&published=${params.published}&source=${params.source}&country=${params.country}&city=${params.city}&isAgreementSigned=${params.isAgreementSigned}`);
  },
  async single(params) {
    return api.get(`properties/${params.id}`);
  },
  async create(values) {
    return api.post(`properties`, values);
  },
  async modify(params, values) {
    return api.put(`properties/${params.id}`, values);
  },
  async remove(params) {
    return api.delete(`properties/${params.id}`);
  },
  async getIsAgreementSigned() {
    return api.get(`properties/has-agreement-signed`);
  },

  nearby: {
    async create(params, values) {
      return api.post(`properties/${params.id}/nearby`, values);
    },
    async remove(params) {
      return api.delete(`properties/${params.id}/nearby/${params.nearbyId}`);
    }
  },

  photos: {
    async create(params, values) {
      return api.post(`properties/${params.id}/photos`, values);
    },
    // post, not delete, because we're sending image url in post data
    async remove(params, values) {
      return api.post(`properties/${params.id}/photos/remove`, values);
    },
    async feature(params, values) {
      return api.post(`properties/${params.id}/photos/feature`, values);
    },
  }
}
export default PropertiesApi;