import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./dashboard";
import Account from "./account";
import asyncComponent from "../../util/asyncComponent";

import PropertiesApp from "./properties";
import AdministratorsApp from "./administrators";
import CountriesApp from "./countries";
import OffersApp from "./offers";
import CitiesApp from "./cities";
import UsersApp from "./users";
import CurrenciesApp from "./currencies";
import FaqApp from "./faq";
import PromoCodesApp from "./promo-codes";
import TermsAndConditionsApp from "./terms-and-conditions";

import UserRatingsApp from "./user-ratings";
import PropertyTypesApp from "./property-types";
import PropertyRatingsApp from "./property-ratings";
import PropertyPoliciesApp from "./property-policies";
import PropertyTermsApp from "./property-terms";

import RoomNamesApp from "./room-names";
import RoomTypesApp from "./room-types";
import BedTypesApp from "./bed-types";
import BedNumbersApp from "./bed-numbers";
import GuestNumbersApp from "./guest-numbers";
import ServicesApp from "./services";

import InvoicesApp from "./invoices";
import BookingsApp from "./bookings";
import AppVersion from "./app-version";
import Commissions from "./commissions";
import SuggestedRatesApp from "./suggested-rates";

import { withRouter } from "react-router";

const Routes = ({ match }) =>
  <Switch>
    <Route path={`${match.url}/dashboard`} component={Dashboard}/>
    <Route path={`${match.url}/properties`} component={PropertiesApp}/>
    <Route path={`${match.url}/administrators`} component={AdministratorsApp}/>
    <Route path={`${match.url}/users`} component={UsersApp}/>
    <Route path={`${match.url}/user-ratings`} component={UserRatingsApp}/>

    <Route path={`${match.url}/invoices`} component={InvoicesApp}/>
    <Route path={`${match.url}/bookings`} component={BookingsApp}/>
    <Route path={`${match.url}/offers`} component={OffersApp}/>

    <Route path={`${match.url}/countries`} component={CountriesApp}/>
    <Route path={`${match.url}/cities`} component={CitiesApp}/>
    <Route path={`${match.url}/currencies`} component={CurrenciesApp}/>
    <Route path={`${match.url}/faq`} component={FaqApp}/>
    <Route path={`${match.url}/promo-codes`} component={PromoCodesApp}/>
    <Route path={`${match.url}/terms-and-conditions`} component={TermsAndConditionsApp}/>
    <Route path={`${match.url}/property-types`} component={PropertyTypesApp}/>
    <Route path={`${match.url}/property-ratings`} component={PropertyRatingsApp}/>
    <Route path={`${match.url}/property-policies`} component={PropertyPoliciesApp}/>
    <Route path={`${match.url}/property-terms`} component={PropertyTermsApp}/>

    <Route path={`${match.url}/room-names`} component={RoomNamesApp}/>
    <Route path={`${match.url}/room-types`} component={RoomTypesApp}/>
    <Route path={`${match.url}/bed-types`} component={BedTypesApp}/>
    <Route path={`${match.url}/bed-numbers`} component={BedNumbersApp}/>
    <Route path={`${match.url}/guest-numbers`} component={GuestNumbersApp}/>
    <Route path={`${match.url}/services`} component={ServicesApp}/>

    <Route path={`${match.url}/account`} component={Account}/>
    <Route path={`${match.url}/app-version`} component={AppVersion}/>
    <Route path={`${match.url}/commissions`} component={Commissions}/>
    <Route path={`${match.url}/suggested-rates`} component={SuggestedRatesApp}/>
    <Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/>
  </Switch>;


export default withRouter(Routes);
