import deepOrange from '@material-ui/core/colors/deepOrange';
import lightBlue from '@material-ui/core/colors/lightBlue';
import shTheme from 'containers/themes/sh-theme';

const main = shTheme.colors.main;
const secondary = shTheme.colors.secondary;

export default {
  palette: {
    primary: {
      light: main[300],
      main: main[500],
      dark: main[700],
      contrastText: '#fff'
    },
    secondary: {
      light: secondary[400],
      main: secondary[600],
      dark: secondary[700],
      contrastText: '#fff'
    }
  },
  status: {
    danger: 'red',
  },
  typography: {
    fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial", sans-serif`,
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    },
  },
};

// export default {
//   palette: {
//     primary: {
//       light: deepOrange[300],
//       main: deepOrange[500],
//       dark: deepOrange[700],
//       contrastText: '#fff'
//     },
//     secondary: {
//       light: lightBlue[300],
//       main: lightBlue['A200'],
//       dark: lightBlue[700],
//       contrastText: '#fff'
//     }
//   },
//   status: {
//     danger: 'orange',
//   },
//   typography: {
//     fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial", sans-serif`,
//     button: {
//       fontWeight: 400,
//       textAlign: 'capitalize'
//     },
//   },
// };
