export const environment = {
  apiUrl: process.env.API_URL || 'https://api-staging.stayhopper.com/admin/v2',
  apiRootUrl: process.env.API_ROOT_URL || 'https://api-staging.stayhopper.com',
  charges: {
    // UAE
    "5b87b5e26ebfc73aed2589f7": [
      {
        name: 'VAT',
        id: 'vat',
        chargeType: 'percentage' // 'value', 'percentage'
      },
      {
        name: 'Tourism Fee',
        id: 'tourism_fee',
        chargeType: 'value' // 'value', 'percentage'
      },
      {
        name: 'Property Service Charge',
        id: 'service_charge',
        chargeType: 'percentage' // 'value', 'percentage'
      },
      {
        name: 'Municipality Fee',
        id: 'muncipality_fee',
        chargeType: 'percentage' // 'value', 'percentage'
      }
    ],

    // India
    "5c4843ef0e24ff1f7b5eafe6": [
      {
        name: 'GST',
        id: 'gst',
        chargeType: 'percentage' // 'value', 'percentage'
      }
    ]
  }
};
