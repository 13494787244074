import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from '../../../util/asyncComponent';

const AdministratorsApp = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/list`}/> */}
      <Route exact path={`${match.url}/`} component={asyncComponent(() => import('./routes/AdministratorsList'))}/>
      <Route exact path={`${match.url}/new`} component={asyncComponent(() => import('./routes/AdministratorDetail'))}/>
      <Route exact path={`${match.url}/:id`} component={asyncComponent(() => import('./routes/AdministratorDetail'))}/>

      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default AdministratorsApp;
