import React from 'react';
import Button from '@material-ui/core/Button';
import { Public } from '@material-ui/icons';

const Footer = () => {
    return (
      <footer className="app-footer row d-none">
        <span className="col-7">Copyright StayHopper &copy; {new Date().getFullYear()}</span>
        <div className="col-5 d-flex p-0">
          <Button
            href="https://stayhopper.com"
            target="_blank"
            size="small"
            color="primary"
            className="ml-auto"
            tabIndex="-1"
          >
            <Public/>
            <span style={{paddingLeft:"5px"}}>StayHopper.com</span>
          </Button>
        </div>
      </footer>
    );
  }
;

export default Footer;
