import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from '../../../util/asyncComponent';

const PropertiesApp = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/list`}/> */}
      <Route exact path={`${match.url}/`} component={asyncComponent(() => import('./routes/PropertiesList'))}/>
      <Route exact path={`${match.url}/new`} component={asyncComponent(() => import('./routes/PropertyDetail'))}/>
      <Route exact path={`${match.url}/:id`} component={asyncComponent(() => import('./routes/PropertyDetail'))}/>

      {/* TODO: For Tabs */}
      {/* <Route exact path={`${match.url}/:id/rooms`} component={asyncComponent(() => import('./routes/PropertyDetail'))}/> */}

      {/* <Route path={`${match.url}/:id/rooms`} component={asyncComponent(() => import('./routes/rooms'))}/> */}
      <Route exact path={`${match.url}/:propertyId/rooms/new`} component={asyncComponent(() => import('./routes/rooms/routes/RoomDetail'))}/>
      <Route exact path={`${match.url}/:propertyId/rooms/:id`} component={asyncComponent(() => import('./routes/rooms/routes/RoomDetail'))}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default PropertiesApp;
