import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from '../../../util/asyncComponent';

const BookingsApp = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/list`}/> */}
      <Route exact path={`${match.url}/`} component={asyncComponent(() => import('./routes/BookingsList'))}/>
      <Route exact path={`${match.url}/:id/:status`} component={asyncComponent(() => import('./routes/BookingDetail'))}/>

      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default BookingsApp;
