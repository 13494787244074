import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from '../../../util/asyncComponent';

const CountriesApp = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      <Route path={`${match.url}/`} component={asyncComponent(() => import('./routes/AppVersion'))}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default CountriesApp;
