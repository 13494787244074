import React from 'react';
import {List, Tooltip} from '@material-ui/core';
import {NavLink, Link} from 'react-router-dom';

import IntlMessages from "../../util/IntlMessages";

const NavMenuItem = props => {
  const {name, label, icon, link, isDisabled, disabledTooltip} = props;
  const navLinkClicked = e => {
    if (isDisabled) {
      return e.preventDefault();
    }
  }

  return (
    <List component="div" className='nav-menu-item'>
      <Tooltip title={isDisabled ? disabledTooltip : ''}>
        <NavLink className="prepend-icon nav-menu-link"
          to={link}
          onClick={navLinkClicked}
          style={isDisabled
            ? {
                opacity: 0.5,
                backgroundColor: 'transparent',
                color: '#666'
              }
            : {}
          }
        >
          {!!icon && (
            <i className={'zmdi zmdi-hc-fw  zmdi-' + icon}/>
          )}
          {/* <span className="nav-text"><IntlMessages id={name}/></span> */}
          {name && <span className="nav-text"><IntlMessages id={name}/></span>}
          {label && <span className="nav-text">{label}</span>}
        </NavLink>
      </Tooltip>
    </List>
  )
};

export default NavMenuItem;