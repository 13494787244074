import { theme } from "@chakra-ui/core";

// 600 is original
const orange =  {
  50: '#ffe1e8',
  100: '#ffb1bb',
  200: '#ff7f8e',
  300: '#ff4c62',
  400: '#ff1a35',
  500: '#e6001b',
  600: '#b40014',
  700: '#81000e',
  800: '#500006',
  900: '#210000'
  // TODO: Changes by Suji reverted
  // 50: '#FFF3EB',
  // 100: '#FFF3EB',
  // 200: '#FFE7D6',
  // 300: '#FFDBC2',
  // 400: '#FFCFAD',
  // 500: '#FFB3A1',
  // 600: '#FF988B',
  // 700: '#FF7D74',
  // 800: '#FF635E',
  // 900: '#FF4848',
};

// 400 is original
const blue = {
  50: '#e6ecff',
  100: '#bbc7f6',
  200: '#8fa2ed',
  300: '#647ce6',
  400: '#3a57df',	  
  500: '#243ec7',
  600: '#1b309b',
  700: '#12226f',
  800: '#091444',
  900: '#01071b'
  // TODO: Changes by Suji reverted
  // 400: '#324FAD',
  // 500: '#263E90',
  // 600: '#1E337C',
  // 700: '#1A2D73',
  // 800: '#12225F',
  // 900: '#0A174C',
};
const SHGradientCTA = 'linear-gradient(270deg, #FF3E3E 0%, #FFC764 100%)';
const SHGradientCTA20 = 'linear-gradient(270deg, rgba(255, 62, 62, 0.2) 0%, rgba(255, 199, 100, 0.2) 100%)';

const themeColors = {
  main: orange,
  secondary: blue
}

const otherColors = {
  custom: {
    bodyBackgroundColor: '#f7f7f7'
  },
  SHGradientCTA,
  SHGradientCTA20
}

// Purple blue - 473494
// Pinkish - ef4085
const composedTheme = {
  ...themeColors,
  ...otherColors
};


const activeTheme = composedTheme;
const shTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    ...activeTheme
  },
  fonts: {
    body: "Roboto, sans-serif",
    heading: "Roboto, sans-serif",
    mono: "Roboto, sans-serif"
  },
  fontSizes: {
    ...theme.fontSizes 
  },

  // ChakraModal > MaterialUISelect (Select is in the background due to zindex, so we move all chakra zindices 1 level down)
  zIndices: {
    hide: -1,
    auto: "auto",
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1250,
    modal: 1300,
    popover: 1400,
    skipLink: 1500,
    toast: 1600,
    tooltip: 1700
  },
  breakpoints: ["30em", "48em", "62em", "75em"]
};

export default shTheme;