import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from '../../../util/asyncComponent';

const OffersApp = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/list`}/> */}
      <Route path={`${match.url}/`} component={asyncComponent(() => import('./routes/OffersList'))}/>
      {/* <Route exact path={`${match.url}/new`} component={asyncComponent(() => import('./routes/OffersList'))}/>
      <Route exact path={`${match.url}/:id`} component={asyncComponent(() => import('./routes/OffersList'))}/> */}

      {/* <Route exact path={`${match.url}/new`} component={asyncComponent(() => import('./routes/OfferDetail'))}/> */}
      {/* <Route exact path={`${match.url}/:id`} component={asyncComponent(() => import('./routes/OfferDetail'))}/> */}

      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default OffersApp;
