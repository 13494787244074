import { useState, useEffect } from 'react';

export default function useWindowResize() {
  const getSize = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  // const defaultSize = {
  //   width: window.innerWidth,
  //   height: window.innerHeight
  // };

  const [windowSize, setWindowSize] = useState(getSize);
  let timer = null;

  useEffect(() => {
    const onResize = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setWindowSize(getSize())
      }, 200);
    }
    window.addEventListener('resize', onResize);
  }, []);

  return windowSize;
}