import {environment as dev} from './environment.dev'
import {environment as prod} from './environment.prod'
import {environment as staging} from './environment.staging'
import {environment as vrbrosStaging} from './environment.vrbros-staging'

// Common config
const config = {
  googleNearbyPlacesType: 'tourist_attraction',
  permissions: {
    SHOW_DASHBOARD: 'SHOW_DASHBOARD',
    SHOW_FULL_DASHBOARD: 'SHOW_FULL_DASHBOARD',
    SHOW_OWN_DASHBOARD: 'SHOW_OWN_DASHBOARD',
    SHOW_SETTINGS: 'SHOW_SETTINGS',
    LIST_PROPERTIES: 'LIST_PROPERTIES',
    LIST_ALL_PROPERTIES: 'LIST_ALL_PROPERTIES',
    LIST_OWN_PROPERTIES: 'LIST_OWN_PROPERTIES',
    LIST_ROOMS: 'LIST_ROOMS',
    LIST_BOOKINGS: 'LIST_BOOKINGS',
    LIST_ALL_BOOKINGS: 'LIST_ALL_BOOKINGS',
    LIST_OWN_BOOKINGS: 'LIST_OWN_BOOKINGS',
    LIST_USERS: 'LIST_USERS',
    LIST_USER_RATINGS: 'LIST_USER_RATINGS',
    LIST_ADMINISTRATORS: 'LIST_ADMINISTRATORS',
    LIST_INVOICES: 'LIST_INVOICES',
    LIST_ALL_INVOICES: 'LIST_ALL_INVOICES',
    LIST_OWN_INVOICES: 'LIST_OWN_INVOICES'
  },
  hours: {
    weightage: {
      h0: 4,
      h1: 4,
      h2: 4,
      h3: 4,
      h4: 4,
      h5: 4,
      h6: 4,
      h7: 4,
      h8: 4,
      h9: 4,
      h10: 4,
      h11: 4,
      h12: 3,
      h13: 3,
      h14: 4,
      h15: 4,
      h16: 4,
      h17: 4,
      h18: 5,
      h19: 5,
      h20: 5,
      h21: 5,
      h22: 5,
      h23: 0, // This will be the remaining amount
    }
  }
}

// Environment specific config
let envConfig;
switch (process.env.NODE_ENV) {
  case 'production':
    if (process.env.REACT_APP_CUSTOM_NODE_ENV && process.env.REACT_APP_CUSTOM_NODE_ENV === 'staging') {
      envConfig = staging;
    } else if (process.env.REACT_APP_CUSTOM_NODE_ENV && process.env.REACT_APP_CUSTOM_NODE_ENV === 'vrbros-staging') {
      envConfig = vrbrosStaging;
    } else {
      envConfig = prod;
    }
    break;
  case 'development':
  default:
    envConfig = dev;
    break;
}

export default {
  ...config,
  ...envConfig
}