import api from '.';

const Auth = {
  async login(values) {
    return api.post('auth/login', values);
  },

  async test() {
    return api.post('auth/authorized');
  },

  async forgotPassword(values) {
    return api.post('auth/reset-password', values);
  },

  async changePassword(values) {
    return api.post('auth/change-password', values);
  },

  async autoLogin(values) {
    return api.post('auth/auto-login', values);
  },

}
export default Auth;