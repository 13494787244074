import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar'
import {useDispatch, useSelector} from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {userSignOut} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import { push } from 'connected-react-router';
const UserInfo = () => {

  const dispatch = useDispatch();
  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);
  const { authUser } = useSelector(({auth}) => auth);

  const handleClick = event => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => setOpen(false);
  const nameInitials = (name) => name.split(' ').map(i => i.substr(0,1).toUpperCase()).join('').substr(0, 2);

  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <div className="user-detail is-clickable">
        <Avatar onClick={handleClick} className="text-white bg-info user-avatar size-40">{nameInitials(authUser.name)}</Avatar>
      </div>
      <Menu className="user-info"
            id="simple-menu"
            anchorEl={anchorE1}
            open={open}
            onClose={handleRequestClose}
            PaperProps={{
              style: {
                minWidth: 120,
                paddingTop: 0,
                paddingBottom: 0
              }
            }}
      >
        <MenuItem disabled>
          <h4 className="user-name d-flex">
            <span className='text-truncate'>{authUser.name}</span>
          </h4>
        </MenuItem>
        {/* <MenuItem onClick={() => {
          handleRequestClose();
          dispatch(push('/app/account'));
        }}>
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
          <span>Account</span>
        </MenuItem> */}
        {/* <MenuItem onClick={handleRequestClose}>
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
          <IntlMessages id="popup.setting"/>
        </MenuItem> */}
        <MenuItem onClick={() => {
          handleRequestClose();
          dispatch(push('/app/account/change-password'));
        }}>
          <i className="zmdi zmdi-lock zmdi-hc-fw mr-2"/>
          <span>Change Password</span>
        </MenuItem>
        <MenuItem onClick={() => {
          handleRequestClose();
          dispatch(userSignOut());
        }}>
          <span className="text-danger">
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>
            <IntlMessages id="popup.logout"/>
          </span>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserInfo;


