import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Dashboard = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      <Route exact path={`${match.url}/`} component={asyncComponent(() => import('./routes/Intranet'))}/>
    </Switch>
  </div>
);

export default Dashboard;