import React from 'react'
import { Button, ButtonProps } from "@chakra-ui/core"
import shTheme from 'containers/themes/sh-theme';

export const SHButtonShades = {
  main: 1,
  secondary: 2,
  dark: 3,
  light: 4,
  cta: shTheme.colors.SHGradientCTA,
}

export const SHButton = ({ shade=SHButtonShades.main, children, ...rest }) => {

  const hasHoverEffect = !rest.isDisabled && !rest.isLoading;
  const hasActiveEffect = !rest.isDisabled && !rest.isLoading;
  const hoverShade = shade === SHButtonShades.cta ? shTheme.colors.SHGradientCTA20 : shade;

  const variantPropsDefault = {
    [SHButtonShades.main]: {
      backgroundColor: 'main.400',
      border: 'none',
      color: 'white',
      fontWeight: '400',
      _hover: hasHoverEffect && {
        backgroundColor: 'main.500'
      },
      _active: hasActiveEffect && {
        backgroundColor: 'main.600'
      }
    },
    // TODO: Changes by Suji reverted
    [SHButtonShades.secondary]: {
      backgroundColor: 'secondary.500',
      border: 'none',
      color: 'white',
      fontWeight: '400',
      _hover: hasHoverEffect && {
        backgroundColor: 'secondary.600'
      },
      _active: hasActiveEffect && {
        background:  'secondary.700'
      }
    },
    [SHButtonShades.dark]: {
      backgroundColor: 'gray.700',
      border: 'none',
      color: 'white',
      fontWeight: '400',
      _hover: hasHoverEffect && {
        backgroundColor: 'gray.600'
      },
      _active: hasActiveEffect && {
        backgroundColor: 'gray.600'
      }
    },
    [SHButtonShades.light]: {
      backgroundColor: 'white',
      border: 'none',
      color: 'main.400',
      fontWeight: '400',
      _hover: hasHoverEffect && {
        backgroundColor: 'gray.100'
      },
      _active: hasActiveEffect && {
        backgroundColor: 'gray.200'
      }
    },
    [SHButtonShades.cta]: {
      background: shade,
      border: 'none',
      color: 'white',
      fontWeight: '400',
      // fontWeight: '500',
      textTransform:'uppercase',
      // fontSize: '14px',
      // height: '38px',
      padding: '2px 30px',
      _hover: hasHoverEffect && {
        opacity: 0.8
      },
      _active: hasActiveEffect && {
        opacity: 0.8
      }
    },
  }

  const variantPropsOutline = {
    [SHButtonShades.main]: {
      color: 'main.600',
      borderColor: 'main.600',
      backgroundColor: 'white',
      _hover: hasHoverEffect && {
        backgroundColor: 'gray.100'
      },
      _active: hasActiveEffect && {
        backgroundColor: 'gray.200'
      }
    },
    [SHButtonShades.secondary]: {
      color: 'secondary.400',
      borderColor: 'secondary.400',
      backgroundColor: 'white',
      _hover: hasHoverEffect && {
        backgroundColor: 'gray.100'
      },
      _active: hasActiveEffect && {
        backgroundColor: 'gray.200'
      }
    },
    [SHButtonShades.dark]: {
      color: 'gray.700',
      borderColor: 'gray.700',
      background: 'transparent',
      fontWeight: '400',
      _hover: hasHoverEffect && {
        backgroundColor: 'gray.100'
      },
      _active: hasActiveEffect && {
        backgroundColor: 'gray.200'
      }
    },
    [SHButtonShades.light]: {
      color: 'gray.700',
      borderColor: 'gray.700',
      background: 'transparent',
      fontWeight: '400',
      _hover: hasHoverEffect && {
        backgroundColor: 'gray.100'
      },
      _active: hasActiveEffect && {
        backgroundColor: 'gray.200'
      }
    },
    [SHButtonShades.cta]: {
      color: 'main.600',
      borderColor: 'main.600',
      background: 'transparent',
      fontWeight: '400',
      _hover: hasHoverEffect && {
        backgroundColor: 'main.50'
      },
      _active: hasActiveEffect && {
        backgroundColor: 'main.100'
      }
    }
  }

  const variantPropsGhost = {
    [SHButtonShades.main]: {
      color: 'main.600',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      _hover: hasHoverEffect && {
        backgroundColor: 'main.50',
        color: 'main.700',
      },
      _active: hasActiveEffect && {
        backgroundColor: 'main.100',
        color: 'main.800',
      }
    },
    [SHButtonShades.secondary]: {
      color: 'secondary.400',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      _hover: hasHoverEffect && {
        backgroundColor: 'secondary.50',
        color: 'secondary.500',
      },
      _active: hasActiveEffect && {
        backgroundColor: 'secondary.100',
        color: 'secondary.600',
      }
    },
    [SHButtonShades.dark]: {
      color: 'color1.600',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      _hover: hasHoverEffect && {
        backgroundColor: 'color1.50',
        color: 'color1.900',
      },
      _active: hasActiveEffect && {
        backgroundColor: 'color1.100',
        color: 'color1.900',
      }
    },
    [SHButtonShades.cta]: {
      color: 'main.600',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      _hover: hasHoverEffect && {
        backgroundColor: 'main.50',
        color: 'main.900',
      },
      _active: hasActiveEffect && {
        backgroundColor: 'main.100',
        color: 'main.900',
      }
    }
  }

  const variantPropsLink = {
    [SHButtonShades.main]: {
      color: 'main.600',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      _hover: hasHoverEffect && {
        color: 'main.700',
        textDecoration: 'underline'
      },
      _active: hasActiveEffect && {
        color: 'main.800'
      }
    },
    [SHButtonShades.secondary]: {
      color: 'secondary.400',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      _hover: hasHoverEffect && {
        color: 'secondary.600',
        textDecoration: 'underline'
      },
      _active: hasActiveEffect && {
        color: 'secondary.700'
      }
    },
    [SHButtonShades.dark]: {
      color: 'color1.800',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      _hover: hasHoverEffect && {
        color: 'color1.700',
        textDecoration: 'underline'
      },
      _active: hasActiveEffect && {
        color: 'color1.800'
      }
    },
    [SHButtonShades.cta]: {
      color: 'main.600',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      _hover: hasHoverEffect && {
        color: 'main.500',
        textDecoration: 'underline'
      },
      _active: hasActiveEffect && {
        color: 'main.600'
      }
    }
  }

  const variantPropsUnstyled = {
    [SHButtonShades.main]: {
      color: 'main.600'
    },
    [SHButtonShades.secondary]: {
      color: 'secondary.400'
    },
    [SHButtonShades.dark]: {
      color: 'color1.800'
    }
  }

  let variantProps = variantPropsDefault;
  let minH = '28px';
  let minW = '80px';

  // TODO: Address these variants too
  switch (rest.variant) {
    case 'ghost':
      variantProps = variantPropsGhost[shade];
      break;
    case 'outline':
      variantProps = variantPropsOutline[shade];
      break;
    case 'link':
      variantProps = variantPropsLink[shade];
      // Reset minW and minH
      minH = '';
      minW = '';
      break;
    case 'unstyled':
      variantProps = variantPropsUnstyled[shade];
      // Reset minW and minH
      minH = '';
      minW = '';
      break;
    case 'solid':
    default:
      variantProps = variantPropsDefault[shade];
      break;
  }

  return (
    <Button
      minH={minH}
      minW={minW}
      fontWeight="400"
      size="sm"
      _focus={{outline: 'none'}}
      {...variantProps}
      {...rest}
    >{children}</Button>
  )
}