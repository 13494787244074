import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  hideMessage,
  showAuthLoader,
  forgotPassword,
} from 'actions/Auth';
import { SHButton } from 'components/Button';

const SignIn = (props) => {

  const [email, setEmail] = useState('');
  // const [email, setEmail] = useState('rahul.vagadiya+administrator@gmail.com');
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(({ auth }) => auth);

  useEffect(() => {
    dispatch(hideMessage());
  }, []);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      props.history.push('/');
    }
  }, [showMessage, authUser, props.history, dispatch]);

  const handleSubmit = (event) => {
    dispatch(showAuthLoader());
    dispatch(forgotPassword({ email }));
    event.preventDefault();
  }

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">

         <div className="app-logo-content d-flex align-items-center justify-content-center">

        </div>

        <div className="app-login-content">

          <div class="login-form-wrapper">
          <Link className="logo-lg" to="/" title="StayHopper">
            <img width="177" src={require("assets/images/logo-color.png")} alt="StayHopper" title="StayHopper" />
          </Link>
            <div className="app-login-header mb-4">
              <h2>Reset your Password</h2>
            </div>
            <div className="app-login-form">
              <form onSubmit={handleSubmit}>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    onChange={(event) => setEmail(event.target.value)}
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 mb-3 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between button-wrapper">
                    <SHButton type="submit">
                      Send Password
                   </SHButton>

                    <Link to="/signin">Back to Sign In</Link>
                  </div>

                </fieldset>
              </form>
            </div>
          </div>


        </div>

      </div>
      {
        loader &&
        <div className="loader-view">
          <CircularProgress />
        </div>
      }
      {showMessage && NotificationManager.error(alertMessage)}
      <NotificationContainer />
    </div>
  );
};


export default SignIn;
