import React, { useEffect, useState } from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";
import {useSelector} from 'react-redux'
import config from '../../config'
import PropertiesApi from 'util/api/properties';

const SideBarContent = () => {
  const { authUser } = useSelector(({auth}) => auth);
  const { permissions } = authUser.role;

  // Dashboard
  const [hasDashboardAccess, setHasDashboardAccess] = useState(permissions.indexOf(config.permissions.SHOW_DASHBOARD) > -1);

  // Hotel Management
  const [hasPropertiesAccess, setHasPropertiesAccess] = useState(permissions.indexOf(config.permissions.LIST_PROPERTIES) > -1);
  const [hasAllPropertiesAccess, setHasAllPropertiesAccess] = useState(permissions.indexOf(config.permissions.LIST_ALL_PROPERTIES) > -1);
  const [hasOwnPropertiesAccess, setHasOwnPropertiesAccess] = useState(permissions.indexOf(config.permissions.LIST_OWN_PROPERTIES) > -1);
  const [hasBookingsAccess, setHasBookingsAccess] = useState(permissions.indexOf(config.permissions.LIST_BOOKINGS) > -1);
  const [hasUserRatingsAccess, setHasUserRatingsAccess] = useState(permissions.indexOf(config.permissions.LIST_USER_RATINGS) > -1);
  const [hasHotelManagementAccess, setHasHotelManagementAccess] = useState(hasPropertiesAccess || hasBookingsAccess || hasUserRatingsAccess);

  // Users
  const [hasUsersAccess, setHasUsersAccess] = useState(permissions.indexOf(config.permissions.LIST_USERS) > -1);
  const [hasAdministratorsAccess, setHasAdministratorsAccess] = useState(permissions.indexOf(config.permissions.LIST_ADMINISTRATORS) > -1);
  const [hasUserManagementAccess, setHasUserManagementAccess] = useState(hasUsersAccess || hasAdministratorsAccess);

  // Accounts
  const [hasInvoicesAccess, setHasInvoicesAccess] = useState(permissions.indexOf(config.permissions.LIST_INVOICES) > -1);
  const [hasAccountManagementAccess, setHasAccountManagementAccess] = useState(hasInvoicesAccess);

  // Settings
  const hasSettingsManagementAccess = permissions.indexOf(config.permissions.SHOW_SETTINGS) > -1;

  // Is Not a super admin (can be Hotel Admin OR Receptionist)
  const isNotSuperAdmin = hasOwnPropertiesAccess && !hasAllPropertiesAccess;
  const [isAtleastOneAgreementSigned, setIsAtleastOneAgreementSigned] = useState(isNotSuperAdmin ? false : true)

  const checkAgreementStatus = () => {
    // Bearer token is not ready yet, so make this auth request in the next cycle
    setTimeout(async () => {
      const axiosResponse = await PropertiesApi.getIsAgreementSigned();
      setIsAtleastOneAgreementSigned(axiosResponse.data.result);
    });
  }

  useEffect(() => {
    if (authUser && isNotSuperAdmin) {
      checkAgreementStatus();
    }
  }, [])

  // Menu
  let navigationMenus = [
    hasDashboardAccess && {
      // name: 'sidebar.main',
      label: 'Main',
      type: 'section',
      children: [
        {
          // name: 'sidebar.dashboard.intranet',
          label: 'Dashboard',
          icon: 'view-dashboard',
          type: 'item',
          link: '/app/dashboard'
        }
      ]
    },
    hasHotelManagementAccess && {
      label: 'Hotel Management',
      type: 'section',
      children: [
        hasPropertiesAccess && {
          label: 'Properties',
          icon: 'hotel',
          type: 'item',
          link: '/app/properties'
        },
        hasBookingsAccess && {
          label: 'Bookings',
          icon: 'receipt',
          type: 'item',
          isDisabled: !isAtleastOneAgreementSigned,
          disabledTooltip: 'Sign the Properties Partnership Agreement to Access',
          link: '/app/bookings'
        },
        hasUserRatingsAccess && {
          label: 'User Ratings',
          icon: 'star-half',
          type: 'item',
          link: '/app/user-ratings'
        }
      ]
    },
    hasUserManagementAccess && {
      label: 'Users',
      type: 'section',
      children: [
        hasAdministratorsAccess && {
          label: 'Administrators',
          icon: 'male',
          type: 'item',
          link: '/app/administrators'
        },
        hasUsersAccess && {
          label: 'Users',
          icon: 'face',
          type: 'item',
          link: '/app/users'
        }
      ]
    },
    hasAccountManagementAccess && {
      label: 'Accounts',
      type: 'section',
      children: [
        hasInvoicesAccess && {
          label: 'Invoices',
          icon: 'account',
          type: 'item',
          isDisabled: !isAtleastOneAgreementSigned,
          disabledTooltip: 'Sign the Properties Partnership Agreement to Access',
          link: '/app/invoices'
        }
      ]
    },
    hasSettingsManagementAccess && {
      label: 'Settings',
      type: 'section',
      children: [
        {
          label: 'General',
          icon: 'settings',
          type: 'collapse',
          link: '',
          children: [
            {
              label: 'Countries',
              icon: '',
              type: 'item',
              link: '/app/countries'
            },
            {
              label: 'Cities',
              icon: '',
              type: 'item',
              link: '/app/cities'
            },
            {
              label: 'Currencies',
              icon: '',
              type: 'item',
              link: '/app/currencies'
            },
            {
              label: 'Offers',
              icon: '',
              type: 'item',
              link: '/app/offers'
            },
            {
              label: 'FAQ',
              icon: '',
              type: 'item',
              link: '/app/faq'
            },
            {
              label: 'Terms & Conditions',
              icon: '',
              type: 'item',
              link: '/app/terms-and-conditions'
            },
            {
              label: 'Promotional Codes',
              icon: '',
              type: 'item',
              link: '/app/promo-codes'
            }
            ,
            {
              label: 'App Version',
              icon: '',
              type: 'item',
              link: '/app/app-version'
            },
            // hasAdministratorsAccess && {
            //   label: 'Commissions',
            //   icon: '',
            //   type: 'item',
            //   link: '/app/commissions'
            // }
          ]
        },
        {
          label: 'Hotels / Properties',
          icon: 'hotel',
          type: 'collapse',
          link: '',
          children: [
            {
              label: 'Property Types',
              icon: '',
              type: 'item',
              link: '/app/property-types'
            },
            {
              label: 'Property Ratings',
              icon: '',
              type: 'item',
              link: '/app/property-ratings'
            },
            {
              label: 'Policies',
              icon: '',
              type: 'item',
              link: '/app/property-policies'
            },
            {
              label: 'Terms',
              icon: '',
              type: 'item',
              link: '/app/property-terms'
            }
          ]
        },
        {
          label: 'Rooms',
          icon: 'home',
          type: 'collapse',
          link: '',
          children: [
            {
              label: 'Room Names',
              icon: '',
              type: 'item',
              link: '/app/room-names'
            },
            {
              label: 'Room Types',
              icon: '',
              type: 'item',
              link: '/app/room-types'
            },
            {
              label: 'Bed Types',
              icon: '',
              type: 'item',
              link: '/app/bed-types'
            },
            {
              label: 'Number of Beds',
              icon: '',
              type: 'item',
              link: '/app/bed-numbers'
            },
            {
              label: 'Number of Guests',
              icon: '',
              type: 'item',
              link: '/app/guest-numbers'
            },
            {
              label: 'Services / Amenities',
              icon: '',
              type: 'item',
              link: '/app/services'
            },
            {
              label: 'Suggested Rates',
              icon: '',
              type: 'item',
              link: '/app/suggested-rates'
            }
          ]
        }
      ]
    }
  ];

  navigationMenus = navigationMenus.filter(menu => !!menu)
  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={navigationMenus}/>
    </CustomScrollbars>
  );
};

export default SideBarContent;
